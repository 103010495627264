var element = document.getElementById('coustemer-vue');
if(element != null)
{
  new Vue({
   	el: '#coustemer-vue',
  	data: {
    	customers: [],
      checkIn: [],
      checkOut: [],
      birthdays: [],
    	pagination: {
        	total: 0, 
        	per_page: 10,
        	from: 1, 
        	to: 0,
        	current_page: 1,
          last_page:0,
    	},
      rules: {
        name: [
          { required: true, message: 'Please input Activity name', trigger: 'blur' }
        ],
        birthday: [
          { type: 'date', required: true, message: 'Please pick a date', trigger: 'change' }
        ],
        email:[
          { required: true, message: 'Please input email address', trigger: 'blur' },
          { type: 'email', message: 'Please input correct email address', trigger: 'blur,change' }
        ]
      },
      dialogVisible: false,
      loading: false,
      loadingButton: false,
      search:'',
      sendingLetter:'',
      sendingEmail:'',
      editCustomer : {
        'id':'',
        'name':'',
        'birthday':'',
        'email':'',
      },
      letterData : {
        'id':'',
        'type':'',
      },
      formErrors:{},
  	},
  	created : function(){
  		this.getVueItems(this.pagination.current_page, '');
      this.getCheckInItems();
      this.getCheckOutItems();
      this.getBirthdayItems();
  	},
  	methods : {
      getVueItems: function(page, search){
        this.loading = true;
        this.$http.get(ajaxCustomer+'?page='+page+'&search='+search).then((response) => {
          Vue.set(this.$data ,'customers', response.data.customers.data);
          Vue.set(this.$data ,'pagination', response.data.pagination);
          Vue.set(this.$data ,'loading', false);
        });
      },
      getCheckInItems: function() {
        this.$http.get(ajaxCheckIn+'?date='+date).then((response) => {
          Vue.set(this.$data ,'checkIn', response.data);
        });
      },
      getCheckOutItems: function() {
        this.$http.get(ajaxCheckOut+'?date='+date).then((response) => {
          Vue.set(this.$data ,'checkOut', response.data);
        });
      },
      getBirthdayItems: function() {
        this.$http.get(ajaxBirthday+'?date='+date).then((response) => {
          Vue.set(this.$data ,'birthdays', response.data);
        });
      },
      changePage: function (page) {
        this.pagination.current_page = page;
        this.getVueItems(page, this.search);
      },
      searching: function () {
        this.getVueItems(1, this.search);
      },
      isSend: function(index) {
        return this.checkIn[index].send_mail.length > 0;
      },
      isSendOut: function(index) {
        return this.checkOut[index].send_mail.length > 0;
      },
      isSendBirthday: function(index) {
        return this.birthdays[index].send_mail.length > 0;
      },
      isEmail: function(index) {
        return this.checkIn[index].customer.email != '-';
      },
      isEmailBirthday: function(index) {
        return this.birthdays[index].email != '-';
      },
      isEmailOut: function(index) {
        return this.checkOut[index].customer.email != '-';
      },
      isRedEdit: function(index) {
        return this.customers[index].birthday == '0000-00-00' 
              && (this.customers[index].email == '' 
              || this.customers[index].email == '-') ? 'danger' : 'primary'; 
      },
      editItem: function(index){
        this.editCustomer.id = this.customers[index].kd_cus;
        this.editCustomer.name = this.customers[index].nm_cus;
        this.editCustomer.birthday = new Date(this.customers[index].dob);
        this.editCustomer.email = this.customers[index].email;
        this.dialogVisible = true;
        this.formErrors={};
      },
      updateItem: function(id){
        this.$refs.editCustomer.validate((valid) => {
          if (valid) {
            this.loadingButton = true;
            var input = this.editCustomer;
            this.$http.post(ajaxUpdateCustomer,input).then((response) => {
              this.changePage(this.pagination.current_page);
              this.getCheckInItems();
              this.getCheckOutItems();
              this.getBirthdayItems();
              this.dialogVisible = false;
              this.$message({
                message: 'Update success',
                type: 'success'
              });
            }, (response) => {
              this.$message.error('Oops, some thinks wrong. Please reload page!');
              this.formErrors = response.data;
            });
            this.loadingButton = false;
          } else {
            return false;
          }
        });
      },
      sendMail:function(index) {
        this.sendingEmail = this.birthdays[index].kd_cus;
        this.$http.get(ajaxUrl+'/ajax/crm/'+ this.birthdays[index].kd_cus +'/email').then((response) => {
          this.$message({
            message: 'Sent email success',
            type: 'success'
          });
          this.getBirthdayItems();
          this.sendingEmail = '';
        });
      },
      sendLetter:function(index, type) {
        if (type == 'check-in') {
          this.sendingLetter = this.checkIn[index].no_res;
          this.letterData.id = this.checkIn[index].no_res;
        } else {
          this.sendingLetter = this.checkOut[index].no_res;
          this.letterData.id = this.checkOut[index].no_res;
        }
        this.letterData.type = type;
        var input = this.letterData;
        this.$http.post(ajaxLetter, input).then((response) => {
          this.$message({
            message: 'Sent email success',
            type: 'success'
          });
          this.getCheckInItems();
          this.getCheckOutItems();
          this.sendingLetter = '';
        });
      },
  	}
  });
}